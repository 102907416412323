.sidebarArea {
	background: #ffffff;
	width: max(300px, 20vw);
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	/* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
}
header {
	background: #f2f3f5;
	width: 90%;
	font-size: 0.9rem;
	border-radius: 0.5rem;
	color: rgb(57, 56, 56);
	transition: 400ms ease-out;
}
h4 {
	color: #212b36;
}
.admin img {
	width: min(50%, 70px);
	padding: 0.2rem;
	margin-right: 0.5rem;
	border-radius: 50%;
}
.admin {
	padding: 0.5rem;
	display: flex;
	align-items: center;
	justify-content: start;
}
.general {
	/* background: lightpink; */
	width: 90%;
	height: 30%;
	border-radius: 0.5rem;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
}

.management {
	/* background: lightpink; */
	width: 90%;
	height: 20%;
	border-radius: 0.5rem;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
}

.apps {
	/* background: lightpink; */
	width: 90%;
	height: 10%;
	border-radius: 0.5rem;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
}
.cat_title {
	color: rgb(48, 47, 47);
}

.selectable {
	padding: 0.5rem;
	font-size: max(0.7rem, 90%);
	color: #2c3640;
	font-weight: bold;
	transition: 400ms ease-out;
}
header:hover {
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	transition: 400ms ease-in;
}
.selectable:hover {
	background: lightgreen;
	color: green;
	border-radius: 0.2rem;
	cursor: pointer;
	transition: 400ms ease-in;
}
