@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;900&display=swap");

* {
  padding: 0;
  margin: 0;
  font-family: Roboto;
}
.home {
  display: flex;
  background: white;
}
.divider {
  width: 2.5px;
  background: #f6f7f8;
}
